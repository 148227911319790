<template>
  <div class="bg-white p-15">
    <div class="flex align-center mb-15">
      <span class="fs-12">车辆编号：</span>
      <el-input
        v-model.trim="searchForm.cph"
        clearable
        placeholder="请输入"
        class="w-150 mr-10"
      />
      <el-button
        type="primary"
        :loading="loading"
        class="ml-20"
        @click="onSearch"
      >搜 索
      </el-button>
      <el-button
        :loading="loading"
        @click="onReset"
      >重 置
      </el-button>
    </div>

    <resize-wrap class="flex-1">
      <BaseTable
        id="weighbridgeJourna"
        ref="xTable1"
        size="mini"
        border
        auto-resize
        resizable
        highlight-current-row
        highlight-hover-row
        export-config
        :align="allAlign"
        :seq-config="{startIndex: (tablePage.currentPage - 1) * tablePage.pageSize}"
        :data="tableData">
        <vxe-table-column type="seq" title="序号" width="100"/>
        <vxe-table-column field="lsh" title="流水号" min-width="170"/>
        <vxe-table-column field="cph" title="车牌号" min-width="140"/>
        <vxe-table-column field="mz" title="毛重" min-width="116"/>
        <vxe-table-column field="pz" title="皮重" min-width="116"/>
        <vxe-table-column field="mzsj" title="毛重时间" min-width="116"/>
        <vxe-table-column field="pzsj" title="皮重时间" min-width="116"/>
        <vxe-table-column field="jlrxm" title="计量人名" min-width="116"/>
      </BaseTable>
    </resize-wrap>

    <vxe-pager
      :loading="loading"
      :current-page="tablePage.pageNum"
      :page-size="tablePage.pageSize"
      :total="tablePage.totalSize"
      :layouts="['Sizes','PrevPage','JumpNumber','NextPage','FullJump','Total']"
      @page-change="handlePageChange"
    />
  </div>
</template>

<script>

import { getWeighbridgePage } from '@/apis/purchaseManage/purchaseManage'

export default {
  data () {
    return {
      searchForm: {
        cph: ''
      },
      // 表格相关
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 50,
        totalSize: 0
      },
      totals: {
        lowCompCount: 0,
        highCompCount: 0,
        totalWeight: 0,
        totalTheoryWeight: 0
      },
      loading: false,
      editData: null,
      detailVisible: false,
      sortVisible: false
    }
  },
  created () {
    this.initList()
  },
  methods: {
    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },
    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },
    async initList () {
      this.loading = true
      const { totalSize, ...pageParams } = this.tablePage
      const params = Object.assign({}, this.searchForm, pageParams)
      const res = await getWeighbridgePage(params)
      this.loading = false
      if (res.code == 200) {
        this.tableData = res.data.rows
        this.tablePage.totalSize = res.data.totalSize
      } else {
        this.$message.error(res.msg)
      }
    },
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.pageNum = currentPage
      this.tablePage.pageSize = pageSize
      this.initList()
    },
    handlerDetail (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.detailVisible = true
    }
  }
}
</script>
